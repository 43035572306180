import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { PushNotifications, PushNotificationSchema, Token, ActionPerformed } from "@capacitor/push-notifications";
import { AlertController } from "@ionic/angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Preferences } from "@capacitor/preferences";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  private apiUrl = environment.FLOCKFINDER_API_URL;

  constructor(
    private http: HttpClient,
    private alertController: AlertController,
    private router: Router,
  ) {}

  private async _storeDeviceToken(token: string) {
    await Preferences.set({ key: "deviceToken", value: token });
  }
  private async _getDeviceToken() {
    return await Preferences.get({ key: "deviceToken" });
  }
  private async _removeDeviceToken() {
    await Preferences.remove({ key: "deviceToken" });
  }

  async softClearToken(): Promise<void> {
    // get the token from the device and set it an inactive on the server
    // can use this when logging out
    const token = await this._getDeviceToken();
    if (token?.value) {
      this._deleteDeviceToken(token.value).subscribe({
        next: () => {
          return Promise.resolve();
        },
        error: error => {
          console.error("Error deleting device token", error);
          return Promise.resolve();
        },
      });
    }
  }

  async reactivateToken(): Promise<void> {
    // get the token from the device and set it to active on the server
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      this._registerNotifications();
    }
  }

  private _addListeners() {
    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      console.log("Push registration success, token: " + token.value);
      this._submitDeviceToken(token.value).subscribe();
      this._storeDeviceToken(token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
      console.log("Push received: " + JSON.stringify(notification));
    });

    // Update the pushNotificationActionPerformed listener
    PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
      console.log("Push action performed: " + JSON.stringify(notification));
      if (notification.notification.data && notification.notification.data.destination) {
        const destination = notification.notification.data.destination;
        if (destination === "accounts") {
          this.router.navigate(["/account"]);
        }
      }
    });
  }

  private async _registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      const alert = await this.alertController.create({
        header: "FlockFinder Notifications",
        subHeader: "only the useful things",
        backdropDismiss: false,
        message:
          "FlockFinder would like to notify you when medications are running low, remind you of repeat treatments, ask you when contributors are requesting access to your holding etc.",
        buttons: ["Okay"],
      });
      await alert.present();
      await alert.onDidDismiss();
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== "granted") {
      this._hardClearToken();
      return;
    }
    const token = await this._getDeviceToken();
    if (token?.value) {
      // this is necessary when someone logs out and logs back in
      this._submitDeviceToken(token.value).subscribe();
    }
    this._addListeners();
    // this won't actually generate a new token, but if it did, we'd capture it with the listener
    await PushNotifications.register();
  }

  private async _hardClearToken(): Promise<void> {
    // this completely removes the token from the device and the server
    // also 'unregisters' it from firebase
    PushNotifications.unregister();
    const token = await this._getDeviceToken();
    this._removeDeviceToken();
    if (token?.value) {
      this._deleteDeviceToken(token.value).subscribe({
        next: () => {
          return Promise.resolve();
        },
        error: error => {
          console.error("Error deleting device token", error);
          return Promise.resolve();
        },
      });
    }
  }

  private _submitDeviceToken(deviceToken: string): Observable<any> {
    const platform = Capacitor.getPlatform();
    const payload = { token: deviceToken, platform: platform };
    return this.http.post(`${this.apiUrl}/api/v1/comms/devices/`, payload);
  }

  private _deleteDeviceToken(deviceToken: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/v1/comms/devices/${deviceToken}/`);
  }
}
