export class Profile {
  constructor(
    public firstName: string,
    public lastName: string,
    public emailAddress?: string,
    public is_confirmed?: boolean,
    public phoneNumber?: string,
    public address?: {
      raw: string;
      street_number?: string;
      route?: string;
      locality?: string;
    },
    public referralCode?: string,
  ) {}
}

export class UserHolding {
  constructor(
    public id: number,
    public ownerType: string,
    public user_id: number,
    public holding: number,
    public holdingName: string,
    public userPaid: boolean,
    public cancellationDate: Date,
    public createdAt?: Date,
    public requestedExtensionEndDate?: Date,
    public firstFlockNumber?: string,
    public lambingDate?: {
      week: number;
      month: number;
    },
    public latestVanNumber?: string,
    public holdingAddress?: {
      raw: string;
      street_number?: string;
      route?: string;
      locality?: string;
    },
    public permissionPending?: boolean | null,
    public permissionGranted?: boolean | null,
    public ownerName?: string,
    public ownerEmail?: string,
  ) {}
}

export type UserHoldingType = {
  id: number;
  ownerType: string;
  user_id: number;
  holding: number;
  holdingName: string;
  userPaid: boolean;
  cancellationDate: Date;
  createdAt?: Date;
  requestedExtensionEndDate?: Date;
  firstFlockNumber?: string;
  lambingDate?: {
    week: number;
    month: number;
  };
  latestVanNumber?: string;
  holdingAddress?: {
    raw: string;
    street_number?: string;
    route?: string;
    locality?: string;
  };
  permissionPending?: boolean | null;
  permissionGranted?: boolean | null;
  ownerName?: string;
  ownerEmail?: string;
};

export class HoldingPermissions {
  constructor(
    public permissionPending: boolean | null,
    public permissionGranted: boolean | null,
  ) {}
}

export class HoldingNew {
  constructor(
    public holdingName: string,
    public user_holdings?: [{ ownerType: string }],
    public flock_numbers?: [{ flockNumber: string }],
    public userPaid?: boolean,
    public requestedExtensionEndDate?: Date,
    public address?: {
      raw: string;
      street_number?: string;
      route?: string;
      locality?: string;
    },
    public van_numbers?: [{ vanNumber: string }],
    public lambingDate?: {
      week: number;
      month: number;
    },
  ) {}
}

export class AllHoldings {
  constructor(
    public id: number,
    public holdingName: string,
    public confirmed: boolean,
    public user_holdings: [
      {
        id: number;
        ownerType: string;
        user_id: number;
        holding: number;
        holdingName: string;
        userPaid: boolean;
        cancellationDate: Date;
        createdAt?: Date;
      },
    ],
    public flock_numbers?: [
      {
        flockNumber: string;
      },
    ],
  ) {}
}

export class GetHolding {
  constructor(
    public id: number,
    public holdingName: string,
  ) {}
}

export class UserMembership {
  constructor(
    public isMember: boolean,
    public wasMember: boolean,
    public planDescription: string,
    public planName: string,
    public cancellationDatetime?: Date,
  ) {}
}

export class ReferralInfo {
  constructor(
    public referralAmount: number,
    public refereeAmount: number,
    public allowReferrals: boolean,
  ) {}
}

export const OwnershipLimits = {
  non_member: 1,
  shepherd_monthly: 1,
  farm_monthly: 1,
  estate_monthly: 3,
  shepherd_yearly: 1,
  farm_yearly: 1,
  estate_yearly: 3,
};

export class VanGuidance {
  constructor(
    public name: string,
    public shortDescription: string,
    public moreInfoUrl: string,
    public faviconUrl?: string,
  ) {}
}
