import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Platform, ToastController } from "@ionic/angular";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { CurrentPlatformService } from "../shared/current-platform.service";
@Injectable({
  providedIn: "root",
})
export class CoreService {
  // log if we are in desktop mode
  // https://devdactic.com/horizontal-navigation-ionic-desktop/
  private _isDesktop = new BehaviorSubject(false);

  // service to hide ion-tabs in particular pages
  // https://medium.com/@JordanBenge/hiding-tabs-dynamically-in-ionic-4-358e509cc00a
  hideTabBarPages = [
    "new-medicine",
    "add-field",
    "add-treatments",
    "treatment",
    "details",
    "medical-cabinet",
    "suppliers",
    "animal",
    "new-medicine",
    "add-animal",
    "add-offspring",
    "add-existing-offspring",
    "add-parents",
    "edit-field",
    "add-weights",
    "labels",
    "metric-session",
  ];
  routeParamPages: string[] = ["view-animal", "animal-weight", "fields", "animal-metric", "birthing"];
  hideMenuBarPages = [
    "login",
    "register",
    "entry",
    "login",
    "register",
    "register-confirmation",
    "user-details",
    "user-type",
    "existing-holding",
    "membership-options",
    "new-holding",
    "new-holding-details",
    "finished",
    "finished-existing",
    "slides",
  ];
  routeParamPagesMenu: string[] = ["entry"];
  constructor(
    private router: Router,
    private platform: Platform,
    private toastController: ToastController,
    private currentPlatformService: CurrentPlatformService,
  ) {
    this.platform.ready().then(() => {
      this.navEvents();
    });
  }

  onResize(size: number) {
    if (this.currentPlatformService.isNative()) {
      this._isDesktop.next(false);
    } else {
      if (size < 568) {
        this._isDesktop.next(false);
      } else {
        this._isDesktop.next(true);
      }
    }
  }

  isDesktopView(): Observable<boolean> {
    return this._isDesktop.asObservable().pipe(distinctUntilChanged());
  }

  async successToast(message: string, duration: number = 2000) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: "top",
      color: "success",
    });
    toast.present();
  }
  async errorToast(message: string, duration: number = 2000) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: "top",
      color: "danger",
    });
    toast.present();
  }

  createAlert(type: "error" | "success", message: string, header?: string, error?: string) {
    if (type === "error") {
      header = "Error";
    }
    const alert = document.createElement("ion-alert");
    alert.header = header;
    alert.message = message;
    alert.buttons = ["OK"];
    if (error) {
      alert.subHeader = error;
    }
    document.body.appendChild(alert);
    return alert.present();
  }

  dateParser(date: Date) {
    return new Date(date).toLocaleDateString("en-GB");
  }

  public hideTabs() {
    const tabBar = document.getElementById("flockTabBar");
    if (tabBar !== null) {
      if (tabBar.style.display !== "none") {
        tabBar.style.display = "none";
      }
    }
  }

  public showTabs() {
    const tabBar = document.getElementById("flockTabBar");
    if (tabBar !== null) {
      if (tabBar.style.display !== "flex") {
        tabBar.style.display = "flex";
      }
    }
  }

  public hideMenu() {
    const menuBar = document.getElementById("flockMenuBar");
    if (menuBar !== null) {
      menuBar.setAttribute("disabled", "true");
    }
  }

  public showMenu() {
    const menuBar = document.getElementById("flockMenuBar");
    if (menuBar !== null) {
      menuBar.setAttribute("disabled", "false");
    }
  }

  // A simple subscription that tells us what page we're currently navigating to.
  private navEvents() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
      this.showHideTabs(e);
    });
  }

  private showHideTabs(e: any) {
    // Split the URL up into an array.
    const urlArray = e.url.split("/");
    // Grab the parentUrl
    const pageUrlParent = urlArray[urlArray.length - 2];
    // Grab the last page url.
    const pageUrl = urlArray[urlArray.length - 1];
    const page = pageUrl.split("?")[0];
    // Check if it's a routeParamPage that we need to hide on
    const hideParamPage = this.routeParamPages.indexOf(pageUrlParent) > -1 && !isNaN(Number(page));
    // Check if we should hide or show tabs.
    const shouldHideTab = this.hideTabBarPages.indexOf(page) > -1 || hideParamPage;
    // Check if it's a routeParamPage that we need to hide on
    const hideParamPageMenu = this.routeParamPagesMenu.indexOf(pageUrlParent) > -1 && !isNaN(Number(page));
    // Check if we should hide or show menu.
    const shouldHideMenu = this.hideMenuBarPages.indexOf(page) > -1 || hideParamPageMenu;
    try {
      setTimeout(() => (shouldHideTab ? this.hideTabs() : this.showTabs()), 10);
    } catch (err) {}
    try {
      setTimeout(() => (shouldHideMenu ? this.hideMenu() : this.showMenu()), 10);
    } catch (err) {}
  }
}
