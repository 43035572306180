export interface AnimalCount {
  male: {
    young: number;
    adults: number;
  };
  female: {
    young: number;
    adults: number;
  };
  unknown: {
    young: number;
    adults: number;
  };
  withdrawn: {
    young: number;
    adults: number;
  };
}

export interface SummaryCount {
  sheep: {
    totalOnFields: number;
    totalIndividuals: number;
    totalInWithdrawal: number;
    numberOfFields: number;
    ewes: number;
    rams: number;
    lambs: number;
    wethers: number;
    tegs: number;
  };
  cattle: {
    totalOnFields: number;
    totalIndividuals: number;
    totalInWithdrawal: number;
    numberOfFields: number;
    cows: number;
    bulls: number;
    heifers: number;
    steers: number;
  };
  mix: {
    numberOfFieldsSheep: number;
    numberOfFieldsCattle: number;
  };
}

export interface BlockingModalScenario {
  message: {
    header: string;
    message: string;
    messageBrowser: string;
  };
  allowAnotherDay: boolean;
  allowAcknowledge: boolean;
  isOwner: boolean;
  membershipExpiring: boolean;
  accountArchived: boolean;
  isDemo: boolean;
}

export const OnboardingSteps = [
  {
    step: 1,
    stepName: "step1",
    title: "Add your first field",
    description1:
      "Let's get started by adding your first field. In FlockFinder, you can map out all your fields and barns, add animals to them, move animals between them or off the farm and view the activity history of each field.",
    description2:
      "FlockFinder's field view allows you to have an overview of your farm without the need to log individual animals.",
  },
  {
    step: 2,
    stepName: "step2",
    title: "Add livestock to your field",
    description1:
      "Now you have a field, it is time to add your livestock. In fields, livestock is managed in groups (not as individuals) so you can easily keep track of the overall number of animals you have on the farm. Select the field you created and click the edit icon next to 'Sheep' or 'Cattle'.",
    description2:
      "You can also manage livestock at an individual level, but more on that in the next step. ",
  },
  {
    step: 3,
    stepName: "step3",
    title: "Add individual animals",
    description1:
      "FlockFinder allows you to manage your animals at both the field and individual level - we understand both views are useful for different purposes.",
    description2:
      "Recording individual animals is useful for lineage tracking, making notes, marking animals as fallen / culled etc. In the Livestock page you can filter your animals, multi-select a group for treatments and, on the web, bulk upload individual records if you wish. We are looking at ways to improve the individual animal record, for example by adding performance metrics, and would love to hear your feedback!",
  },
  {
    step: 4,
    stepName: "step4",
    title: "Add to your medical cabinet",
    description1:
      "Your medical cabinet is where you store your medicines for use in treatments. Here you can log medications, track which medications are running low or nearing their expiration, link suppliers with medications, mark medications as disposed and their methods of disposal etc.",
    description2:
      "FlockFinder will automatically add or subtract the amount of medication left as you use them in treatments. You can also generate a report of your medical cabinet at any time.",
  },
  {
    step: 5,
    stepName: "step5",
    title: "Log your first treatment",
    description1:
      "Now that you have a medical cabinet, you can add a treatment. When initiating a treatment, FlockFinder will show a default dose based on the medication info and estimated animal weight. You will also be able to add a reason for the treatment, and  a withholding period if applicable.",
    description2:
      "Logging treatments on FlockFinder can be done at the individual or group level - you can even select a field to treat! Giving this option allows for flexibility in how you manage your farm. A treatment report can be generated at any time.",
  },

  {
    step: 6,
    stepName: "step6",
    title: "Register a birth",
    description1:
      "Once you have a Dam, you can register a birth from the individual animal record or by selecting 'Register a birth' from the Home page. The lambs will be added as new individual animals with birth weights and be linked to the Dam's record (and Sire if applicable).",
    description2:
      "FlockFinder has made this process as simple as possible. An overview of lineage can be found by clicking 'View Lineage' on the Livestock page or by generating a Lineage report. We are still looking for ways to improve the lineage overview and insights we can provide so any feedback is appreciated.",
  },
  {
    step: 7,
    stepName: "step7",
    title: "Generate a report",
    description1:
      "The purpose of FlockFinder is to make your life easier by providing you with the means to manage your farm and meet regulatory requirements. The reports are designed to highlight your impressive record-keeping and give you insights on your farm.",
    description2:
      "We have a number of reports available for your farm and we are continually working on ways to improve them.",
  },
];

export class OnboardingStepsCompleted {
  constructor(
    public step1: boolean = true,
    public step2: boolean = true,
    public step3: boolean = true,
    public step4: boolean = true,
    public step5: boolean = true,
    public step6: boolean = true,
    public step7: boolean = true
  ) {}
}

export class OnboardingTasks {
  constructor(
    public hasFields: number,
    public hasFieldLivestock: number,
    public hasAnimals: number,
    public hasMedical: number,
    public hasBulkTreatment: number,
    public hasBirths: number,
    public hasReports: number
  ) {}
}
