import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthenticationService } from "./authentication-service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  urlsToNotUse: Array<string>;

  constructor(
    public ngFireAuth: AngularFireAuth,
    private authService: AuthenticationService
  ) {
    this.urlsToNotUse = [
      "auth/token/refresh",
      "auth/register",
      "auth/token",
      "auth/emailtoken/.",
      "auth/reset-password",
      "auth/reset-password/.",
    ];
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req.url)) {
      return this.ngFireAuth.idToken.pipe(
        take(1),
        switchMap((idToken) => {
          if (idToken === null) {
            this.authService.SignOut();
            return throwError(() => "No idToken on user");
          }
          const clonedReq = this.addToken(req, idToken);
          return next.handle(clonedReq).pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                return throwError(() => error);
              } else {
                return throwError(() => error);
              }
            })
          ) as any;
        })
      ) as any;
    }

    return next.handle(req);
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = "api/";
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(
        position + positionIndicator.length
      );
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }

  private addToken(req: HttpRequest<any>, token: string) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = req.clone({
        setHeaders: {
          Authorization: token,
        },
      });
      return clone;
    }
    return req;
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //     return this.authService.getAccessTokenUsingRefreshToken()
  //         .pipe(switchMap((resp: RefreshResponse) => {
  //             return next.handle(this.addToken(request, resp.access))
  //                 .pipe(catchError(error => {
  //                     return throwError(() => error);
  //                 }));
  //         }));
  // }
}
