import { Injectable } from "@angular/core";
import { CanLoad, Route, UrlSegment, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { take, switchMap, tap } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { CurrentPlatformService } from "../current-platform.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad {
  constructor(
    private router: Router,
    private ngFireAuth: AngularFireAuth,
    private currentPlatformService: CurrentPlatformService
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.ngFireAuth.authState.pipe(
      take(1),
      switchMap((user) => {
        const isLoggedIn =
          user !== null && user.emailVerified !== false ? true : false;
        if (!isLoggedIn) {
          const isAndroid: boolean = this.currentPlatformService.isAndroid();
          const isApple: boolean = this.currentPlatformService.isApple();
          if (isAndroid || isApple) {
            this.router.navigateByUrl("/slides");
          } else {
            this.router.navigateByUrl("/entry/login");
          }
        }
        return of(isLoggedIn);
      })
    );
  }
}
