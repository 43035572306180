export const environment = {
  production: true,
  version: "2.12.1",
  FLOCKFINDER_API_URL: "https://api-stg.flockfinder.co.uk",
  WEBSITE_ADDRESS: "https://flockfinder-staging.web.app",
  mapsKey: "AIzaSyB9Xt0V7zWmVLGZ_XiFJlNo78WUBYfK_GA",
  firebaseConfig: {
    apiKey: "AIzaSyD7570agDlO-6aNI1QxiafzwOnDcA58EbM",
    authDomain: "flockfinder-staging.firebaseapp.com",
    projectId: "flockfinder-staging",
    storageBucket: "flockfinder-staging.appspot.com",
    messagingSenderId: "1085919146476",
    appId: "1:1085919146476:web:c98e3df108f9ebbcca90c9",
    measurementId: "G-6WDPLGS6HV",
  },
};
