import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastController } from "@ionic/angular";
import { AccountService } from "src/app/account/account.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./authentication-service";

export const maxRetries = 1;
export const delayMs = 2000;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastController: ToastController,
    private accountService: AccountService,
    private authService: AuthenticationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = "";

        // client-side error
        if (error.error instanceof ErrorEvent) {
          // display error message
          errorMsg = `Error: ${error.error.message}`;
          this._errorToast(errorMsg);
        }
        // server-side error
        else {
          if (error.status === 500 && environment.production) {
            //  redirect to error page
            this.accountService.sendErrorReport(error.message).subscribe();
            this._errorToast(
              "Something went wrong - the team have been notified, please try again later",
              "danger",
              10000,
            );
          } else {
            // display error message
            const _error = error.error?.detail || error.message || error;
            errorMsg = `Error: ${_error}`;
            // if contains the word token
            if (_error.toLowerCase().includes("token")) {
              this.authService.SignOut();
              errorMsg += " - please login again";
              this._errorToast(errorMsg);
            } else {
              this._errorToast(errorMsg);
            }
          }
        }
        return throwError(() => error);
      }),
    );
  }

  async _errorToast(errorMessage: string, color: string = "tertiary", duration: number = 4000) {
    const toast = await this.toastController.create({
      message: errorMessage,
      position: "top",
      duration: duration,
      color: color,
    });
    toast.present();
  }
}
