import { Injectable } from "@angular/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Device } from "@capacitor/device";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  analyticsEnabled = true;
  constructor(private router: Router) {
    this.initFb();
  }

  async initFb() {
    if ((await Device.getInfo()).platform === "web") {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setScreenName(event.url);
      }
    });
  }

  setUserIdentifier(id: string) {
    FirebaseAnalytics.setUserId({ userId: id });
  }

  logEvent(name: string, params: any) {
    FirebaseAnalytics.logEvent({ name, params });
  }

  setScreenName(name: string) {
    FirebaseAnalytics.setScreenName({ screenName: name });
  }

  toggleAnalyticsCollection() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setCollectionEnabled({ enabled: this.analyticsEnabled });
  }
}
